import logo from './logo.svg';
import './App.css';
import QtdProvider from "./context/qtdContext";
import { BrowserRouter, Route, Routes, useNavigate, useLocation, Navigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
// import MenuMobile from "./components/Header/MenuMobile/MenuMobile";
import LinksMenu from "./components/Header/LinksMenu";
// import Home from './pages/Admin/DashBoard/Dashboard';
import Auth from './pages/auth/Auth';
// import Sclad from './pages/Admin/Sclad/Sclad';
import Shops from './pages/Admin/Shops/Shops';
import Employees from './pages/Admin/Employees/Employees';
import ShopPage from './pages/Admin/ShopPage/ShopPage';
import ProgressBar from "@ramonak/react-progress-bar";
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import 'animate.css/animate.compat.css'
function App() {
  return (
    <QtdProvider>
      
      <ReactNotifications />
      <div>
        <div className="bg"></div>
        <div className="content">

          <BrowserRouter>
            {/* <div className="mobile_app">
            <MenuMobile />
          </div> */}
            {/* <div className="header_app"> */}

            {/* </div> */}
            <Routes>
              <Route path="*" element={<Navigate to="/" />} />
              {/* <Route path="/home" element={<Home />}></Route> */}
              {/* <Route path="/" >
           {/* <h1><>hi</></h1>  */}
              {/* </Route> */}
              <Route path="/" element={<Auth />}></Route>

              {/* <Route path="/home/sclad" element={<Sclad />}></Route>
          <Route path="/home/shops" element={<Shops />}></Route>
          <Route path="/home/employees" element={<Employees />}></Route>

                 <Route path="" element={<ShopPage />}></Route> */}



              <Route path="/shoppage/:id" element={<ShopPage />}>                 </Route>


            </Routes>

          </BrowserRouter>
        </div>
      </div>
    </QtdProvider>
  );
}

export default App;
