import React from "react";
import style from "./Home.module.css";
import { Routes, Route, Link, Navigate } from 'react-router-dom';
import { QtdContext } from "../../../context/qtdContext";
import Button from '../../../components/Button/Button'
import 'react-tabs/style/react-tabs.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { FcCancel, FcEngineering } from "react-icons/fc";
import $api from '../../../http/index.ts'
import './react-confirm-alert.css'; // Import css
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useEffect, useState, useContext } from "react";
import LinksMenu from "../../../components/Header/LinksMenu";
// import AddProductModal from "../../../components/Modals/AddProductModal";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import AddEmployeesModal from "../../../components/Modals/Emloyees/AddEmployeesModal.js";
import EditEmployeesModal from "../../../components/Modals/Emloyees/EditEmployeesModal.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const Statistic = () => {
  const { Notify, editEmployeesModal, smenas, admin, shops, admins, addEmployeesModal, setAddEmployeesModal, setDashboarPage, setAdmin } = useContext(QtdContext);
  const [loading, seloading] = useState(false)
  const [id, setId] = useState('')
  // const [statData, setStatData] = useState([])
  // const [labels, setlabels] = useState([])
  const [days, setDays] = useState(7)

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);



  if (!(admin.role == 'AllRights' || admin.role == 'Администратор')) {
    setDashboarPage('Home')
    // setAdmin({ auth: false});
    return <Navigate to="/" replace />

  } else {
    // const days = 7
    // const yesterday = new Date();
    // yesterday.setDate(yesterday.getDate() - days);
    // yesterday.setHours(0, 0, 0, 0); // Устанавливаем время в начало дня
    // const today = new Date();
    // today.setHours(23, 59, 59, 999); // Устанавливаем время в конец дня
    // const month = -1;
    const yesterday = startDate ? new Date(startDate) : null;
    const today = endDate ? new Date(endDate) : null;

    if (yesterday && today) {
      yesterday.setHours(0, 0, 0, 0); // Устанавливаем время в начало дня
      today.setHours(23, 59, 59, 999); // Устанавливаем время в конец дня
    }



    // console.log('Начало дня вчерашней даты:', yesterday);
    // console.log('Конец дня сегодняшней даты:', today);

    // Фильтрация данных по датам
    const filteredData = smenas.filter(item => {
      const createdAtDate = new Date(item.createdAt);
      return createdAtDate >= yesterday && createdAtDate <= today && !item.open;
    });

    // Группировка данных по датам
    const groupedDataByDate = filteredData.reduce((acc, curr) => {
      const createdAtDate = new Date(curr.createdAt);
      const dateKey = new Date(createdAtDate.getFullYear(), createdAtDate.getMonth(), createdAtDate.getDate());
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(curr);
      return acc;
    }, {});

    // Обобщение данных по магазинам внутри каждой группы
    const groupedData = Object.keys(groupedDataByDate).map(dateKey => {
      const shopData = groupedDataByDate[dateKey].reduce((acc, curr) => {
        const shopIndex = acc.findIndex(item => item.shop_id === curr.shop_id);
        if (shopIndex !== -1) {
          acc[shopIndex].money += curr.money;
        } else {
          acc.push({
            shop_id: curr.shop_id,
            shop_name: shops.find(shop => shop.id === curr.shop_id).name,
            money: curr.money
          });
        }
        return acc;
      }, []);
      return {
        date: new Date(dateKey), // Преобразование в объект Date
        shops: shopData
      };
    });

    // Сортировка данных по датам
    groupedData.sort((a, b) => a.date - b.date);

    let labels = [];
    let datasets = [];

    // Создаем массив с датами от yesterday до today
    const allDates = [];
    let currentDate = new Date(yesterday); // Начинаем с вчерашней даты
    while (currentDate <= today) {
      allDates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1); // Переходим к следующей дате
    }

    // Создаем массив данных для каждого магазина
    const shopDataMap = new Map();
    groupedData.forEach(group => {
      group.shops.forEach(shop => {
        if (!shopDataMap.has(shop.shop_name)) {
          shopDataMap.set(shop.shop_name, Array(allDates.length).fill(0));
        }
      });
    });

    // Заполняем данные для каждого магазина
    groupedData.forEach(group => {
      const dateString = `${group.date.getDate()}.${group.date.getMonth() + 1}.${group.date.getFullYear()}`;
      labels.push(dateString); // Добавляем метку (дату) в labels
      group.shops.forEach(shop => {
        const shopData = shopDataMap.get(shop.shop_name);
        const index = allDates.findIndex(date => {
          return date.getDate() === group.date.getDate() && date.getMonth() === group.date.getMonth() && date.getFullYear() === group.date.getFullYear();
        });
        shopData[index] = shop.money;
        shopDataMap.set(shop.shop_name, shopData);
      });
    });

    // Заполняем массив datasets данными для каждого магазина
    shopDataMap.forEach((data, shopName) => {
      let summ = 0
      for (let i = 0; i < data.length; i++) {
        summ = summ + Number(data[i])
      }
      console.log(data)
      datasets.push({
        label: `${shopName}`,
        data: data,
        backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`
      });
    });

    // Добавляем отсутствующие метки в labels и соответствующие им данные в datasets
    allDates.forEach(date => {
      const dateString = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
      if (!labels.includes(dateString)) {
        labels.push(dateString);
        datasets.forEach(dataset => {
          dataset.data.push(0); // Добавляем данные 0 для отсутствующей метки
        });
      }
    });

    // Сортируем labels в правильном порядке
    labels.sort((a, b) => {
      const [dayA, monthA, yearA] = a.split('.');
      const [dayB, monthB, yearB] = b.split('.');
      const dateA = new Date(yearA, monthA - 1, dayA); // -1, потому что месяцы в JavaScript начинаются с 0
      const dateB = new Date(yearB, monthB - 1, dayB);
      return dateA - dateB;
    });


    // Опции для графика
    const options = {
      legend: {
        labels: {
          // Тут вы можете изменить названия лейблов в легенде
          generateLabels: (chart) => {
            const labels = chart.data.datasets.map((dataset, datasetIndex) => {
              return {
                text: `My custom label ${datasetIndex + 1}`,
                fillStyle: dataset.backgroundColor,
                strokeStyle: dataset.borderColor,
                lineWidth: 2,
              };
            });

            return labels;
          },
        },
      },
      plugins: {
        title: {
          display: true,
          text: 'Выручка на точках',
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    };




    ////USERS STAT
    // Обобщение данных по магазинам внутри каждой группы
    // console.log(groupedDataByDate)
    const groupedDataUsers = Object.keys(groupedDataByDate).map(dateKey => {
      const shopData = groupedDataByDate[dateKey].reduce((acc, curr) => {
        const shopIndex = acc.findIndex(item => item.admin_id === curr.admin_id);
        if (shopIndex !== -1) {
          acc[shopIndex].money += curr.money;
        } else {
          acc.push({
            admin_id: curr.admin_id,
            shop_name: admins.find(shop => shop.id === Number(curr.admin_id)).fullname,
            money: curr.money
          });
        }
        return acc;
      }, []);
      return {
        date: new Date(dateKey), // Преобразование в объект Date
        shops: shopData
      };
    });

    // Сортировка данных по датам
    groupedDataUsers.sort((a, b) => a.date - b.date);

    let labelsAdmins = [];
    let datasetsAdmins = [];

    // Создаем массив с датами от yesterday до today
    // const allDates = [];
    // let currentDate = new Date(yesterday); // Начинаем с вчерашней даты
    // while (currentDate <= today) {
    // allDates.push(new Date(currentDate));
    // currentDate.setDate(currentDate.getDate() + 1); // Переходим к следующей дате
    // }

    // Создаем массив данных для каждого магазина
    const AdminDataMap = new Map();
    groupedDataUsers.forEach(group => {
      group.shops.forEach(shop => {
        if (!AdminDataMap.has(shop.shop_name)) {
          AdminDataMap.set(shop.shop_name, Array(allDates.length).fill(0));
        }
      });
    });

    // Заполняем данные для каждого магазина
    groupedDataUsers.forEach(group => {
      const dateString = `${group.date.getDate()}.${group.date.getMonth() + 1}.${group.date.getFullYear()}`;
      labelsAdmins.push(dateString); // Добавляем метку (дату) в labelsAdmins
      group.shops.forEach(shop => {
        const shopData = AdminDataMap.get(shop.shop_name);
        const index = allDates.findIndex(date => {
          return date.getDate() === group.date.getDate() && date.getMonth() === group.date.getMonth() && date.getFullYear() === group.date.getFullYear();
        });
        shopData[index] = shop.money;
        AdminDataMap.set(shop.shop_name, shopData);
      });
    });

    // Заполняем массив datasets данными для каждого магазина
    AdminDataMap.forEach((data, shopName) => {
      let summ = 0
      for (let i = 0; i < data.length; i++) {
        summ = summ + Number(data[i])
      }

      console.log(data)
      datasetsAdmins.push({
        label: `${shopName}`,
        data: data,
        backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`
      });
    });

    // Добавляем отсутствующие метки в labelsAdmins и соответствующие им данные в datasetsAdmins
    allDates.forEach(date => {
      const dateString = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
      if (!labelsAdmins.includes(dateString)) {
        labelsAdmins.push(dateString);
        datasetsAdmins.forEach(dataset => {
          dataset.data.push(0); // Добавляем данные 0 для отсутствующей метки
        });
      }
    });

    // Сортируем labelsAdmins в правильном порядке
    labelsAdmins.sort((a, b) => {
      const [dayA, monthA, yearA] = a.split('.');
      const [dayB, monthB, yearB] = b.split('.');
      const dateA = new Date(yearA, monthA - 1, dayA); // -1, потому что месяцы в JavaScript начинаются с 0
      const dateB = new Date(yearB, monthB - 1, dayB);
      return dateA - dateB;
    });

    // Опции для графика
    const optionsAdmins = {
      plugins: {
        title: {
          display: true,
          text: 'Выручка по сотрудникам',
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    };

    const handleDateChange = dates => {
      console.log(dates)
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    };
  


    ////
    // console.log({ labelsAdmins, datasetsAdmins, groupedDataUsers });
    return (<>

      <div className={style.sclad_container}>
        <div className={style.borders}
        //  style={{ width: '100%' }}
        >
          <DatePicker
            selected={startDate}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
          />

          {/* <select
            onChange={(event) => {
              setDays(event.target.value)

            }}>
            <option value={7}>7 дней</option>
            <option value={14}>14 дней</option>
            <option value={30}>30 дней</option>
            <option value={90}>90 дней</option>
            <option value={180}>180 дней</option>

          </select> */}
          {/* <div className={style.plate} style={{ display: 'flex',width: '100%' }}> */}
          <div className={style.trx} style={{ width: '100%', display:'flex' }}>
            <Bar style={{ width: "100%" }} options={options} data={{ labels: labels, datasets: datasets }} />
          </div>
          <div className={style.trx} style={{ width: '100%', display:'flex' }}>
            <Bar style={{ width: "100%" }} options={optionsAdmins} data={{ labels: labelsAdmins, datasets: datasetsAdmins }} />
          </div>

          {/* </div> */}
        </div>

      </div>

      {editEmployeesModal && (
        <EditEmployeesModal
          id={id}

        />

      )}

      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}


    </>
    );
  }


};

export default Statistic;
