import React, { useContext, useEffect, useState, useRef } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';

import { RiDiscountPercentFill } from "react-icons/ri";
import { CiPercent } from "react-icons/ci";
import { confirmAlert } from 'react-confirm-alert'; // Import
import './react-confirm-alert.css'; // Import css
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { Store } from 'react-notifications-component';
import ProgressBar from "@ramonak/react-progress-bar";
import 'animate.css/animate.min.css';
import { BiSolidSave } from "react-icons/bi";
import { RiCheckboxBlankCircleLine } from "react-icons/ri";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import ComfirmReportModal from "./ComfirmReportModal.js";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FcOk, FcUp } from "react-icons/fc";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { MdOutlineRadioButtonUnchecked, MdOutlineCheckCircleOutline } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
const SaleReportSteps = ({ shop, smena, CloseSmenaReturn }) => {
  const { checkAuth, setAdmin, setComfirmReportModal, saleReports, setSaleReports, Notify, coffeeMashins, saleReportForms, saleReportSteps, setSaleReportSteps, scladShops, reciepts, sclad, setEditShopModal, admins, setAdmins, admin } = useContext(QtdContext);
  const [loading, seloading] = useState(false)
  const [selectSales, setSelectSales] = useState('')
  const [selectRem, setSelectRem] = useState('')
  const cm = coffeeMashins.find(pr => pr.shop_id == shop.id)
  const cmReciepts = cm.reportsCounters.map(pr => JSON.parse(pr))
  const ref = useRef(null);


  const srform = saleReportForms.find(forms => forms.id == shop.slform_id)
  // console.log('srform', srform)
  const ieconStyle = {
    borderRadius: '10px',
    fontWeight: 'bold',
    color: 'white',

    margin: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',

    textAlign: 'center',
    height: '40px',
    width: '50px',
    border: 'solid white 3px'
  }
  const nosorted = srform.reciepts.map(forms => {
    const parsedForm = JSON.parse(forms);
    const cmCounters = cmReciepts.find(pr => (pr.id == parsedForm.id) && parsedForm.kategory == 'Кофемашина')
    // console.log(cmCounters, parsedForm)
    const recip = reciepts.find(rr => rr.id == parsedForm.id)
    // console.log(recip)


    if (cmCounters) {
      const linked = parsedForm.linked.map(link_id => {
        const recip1 = reciepts.find(rr => rr.id == link_id)
        return {
          ...recip1,
          saleqty: 0,
          sold: 0,
          price: recip1.price,
          lostqty: 0,
          lostcomment: '',
          saleqty: 0,
          salecomment: '',
          sale10: 0,
          sale20: 0,
          sale30: 0,
          lostwithoutcup: 0,
          salewithoutcup: 0
        }
      })

      return {
        ...parsedForm,
        price: recip.price,
        oldqty: cmCounters.counter,
        newqty: cmCounters.counter,
        linked: linked,
        saleqty: 0,
        sold: 0,
        lostqty: 0,
        lostcomment: '',
        saleqty: 0,
        salecomment: '',
        sale10: 0,
        sale20: 0,
        sale30: 0,
        lostwithoutcup: 0,
        salewithoutcup: 0
      }
    } else {
      return {
        ...parsedForm,
        price: recip.price,
        oldqty: 0,
        sold: 0,
        newqty: 0,
        saleqty: 0,
        lostqty: 0,
        lostcomment: '',
        saleqty: 0,
        salecomment: '',
        sale10: 0,
        sale20: 0,
        sale30: 0,
        lostwithoutcup: 0,
        salewithoutcup: 0
      }
    }

  })
  const [newSRID, setNewSRID] = useState({
    id: null,
    shop_id: shop.id,
    smena_id: smena.id
  })

  const [newSR, setNewSR] = useState(nosorted)
  const [page, setPage] = useState('REPORT')
  const [actionId, setactionId] = useState({})

  const [smenasclad, setSmenaSclad] = useState([])

  useEffect(() => {
    const smenascladd = smena.sclad.map(pr => JSON.parse(pr))

    const scladstate = srform.sclad.map(pr => {
      // console.log('в приходе и расходе - смотреть есть ли сохраненый отчет и если нет то создавать')
      const item = JSON.parse(pr)
      console.log('dwedwe', item)
      const sc = smenascladd.find(pr => pr.prod_id == item.id)
      if (sc) {
        console.log('Scladtest', sc)
        const prpr = sc.prihod ? sc.prihod : 0
        const prrs = sc.rashod ? sc.rashod : 0
        return { ...sc, prihod: prpr, rashod: prrs, andqty: sc.scldqty + prpr - prrs, comfirm: false }
      } else {

        const prpr = item.prihod ? item.prihod : 0
        const prrs = item.rashod ? item.rashod : 0
        return { ...item, prihod: prpr, rashod: prrs, fact: item.scldqty + prpr - prrs, comfirm: false, andqty: item.scldqty + prpr - prrs }
      }
    })
    setSmenaSclad(scladstate)
    console.log('sclad', smenascladd)
    const saved = saleReports.find(pr => (pr.smena_id == smena.id && pr.shop_id == shop.id && pr.status == 'Сохранен'))
    if (saved) {
      setNewSRID({
        id: saved.id,
        shop_id: saved.shop_id,
        smena_id: saved.smena_id
      })
      // console.log(saved.sclad, saved.reciepts)
      const nosortedsaved = saved.reciepts.map(forms => JSON.parse(forms))

      const sg = srform.reciepts.map(pr => {
        const item = JSON.parse(pr)
        const recip = reciepts.find(rr => rr.id == item.id)
        const test = nosortedsaved.find(pr => pr.id == item.id)
        if (test) {
          return test
        } else {
          return {
            ...item,
            price: recip.price,
            saleqty: 0,
            sold: 0,
            lostqty: 0,
            lostcomment: '',
            saleqty: 0,
            salecomment: '',
            sale10: 0,
            sale20: 0,
            sale30: 0,
            lostwithoutcup: 0,
            salewithoutcup: 0
          }
        }

      })

      setNewSR(sg)

      // const sc = saved.sclad.map(forms => JSON.parse(forms))

      // const parsedSclad = sc.map(pr => {
      //   // const item = JSON.parse(pr)
      //   const scladsaved = sc.find(pr => pr.prod_id == item.prod_id)
      //   const prpr = item.prihod ? item.prihod : 0
      //   const prrs = item.rashod ? item.rashod : 0
      //   if (scladsaved) {
      //     return { ...item, prihod: prpr, rashod: prrs, fact: item.scldqty + prpr - prrs, andqty: item.scldqty + prpr - prrs }

      //   } else {

      //     return { ...item, prihod: prpr, rashod: prrs, fact: item.scldqty + prpr - prrs, andqty: item.scldqty + prpr - prrs }
      //   }
      // })
      // setSmenaSclad(sc)
    }
  }, [])





  const savesr = async () => {
    try {
      seloading(true)
      const refresh = await checkAuth()
      if (refresh) {
        // console.log(newSRID)
        const newProd = await $api.post(`${BACKURL}/api/salereports/save`,
          { newSRID: newSRID, newSR: newSR, smenasclad: smenasclad, admin: admin.id }

        )
        // console.log(newProd.data)
        const test = saleReports.find(pr => pr.id == newProd.data.id)
        setNewSRID({
          id: newProd.data.id,
          shop_id: newProd.data.shop_id,
          smena_id: newProd.data.smena_id
        })


        if (test) {
          setSaleReports(saleReports.map(pr => {
            if (pr.id == newProd.data.id) {
              return newProd.data
            } else {
              return pr
            }
          }))
        } else {
          setSaleReports([...saleReports, newProd.data])
        }

        // console.log(newProd.body)



        Notify.addNotification({
          title: "Готово!",
          message: "Сохранено, вы можете продолжить заполнять отчет в любой момент!",
          type: "success",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }
      seloading(false)
      // setTimeout(()=>{
      //   Store.removeNotification(test)
      // },10000)
    } catch (e) {
      seloading(false)
      Notify.addNotification({
        title: "Ошибка!",
        message: "Произошла непредвиденная ошибка!",
        type: "danger",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }

  }

  function customSort2(a, b) {
    const categoriesOrder = {
      'эспрессо': 1,
      'американо': 2,
      'капучино': 3,
      'латте': 4,
      'мокаччино': 5,
      'раф-кофе': 6,
      'флэт-уайт': 7,
      'вишнессо': 8,
      'какао': 9,
      'какао': 10,
      'айс латте': 11,

      'чай': 12,
      'сливки': 13,
      'сироп': 14,
      'мёд': 15,
      'маршмеллоу': 16,
      'лимон': 17,
      'растительное молоко': 18,
      'молоко': 19,

      'пунш': 19,
      'глинтвейн': 20,
      'вода': 21,
      'мини-сок': 22,
      'мохито': 23,
      'лимонад': 24,
      'сангрия': 25,
      'холодный мятный чай': 26,
      'choco pie': 27,
      'сладкая вата': 28,
      'milky way': 29,
      'резинка': 30,
      'панини': 31,
      'кекс': 32,
      'круассан': 33,
      'Прочее': 34,
    };

    const getCategory = (name) => {
      for (const category in categoriesOrder) {
        if ((name.toLowerCase()).includes(category)) {
          return category;
        }
      }
      return 'Прочее';
    };

    const categoryA = getCategory(a.name);
    const categoryB = getCategory(b.name);

    if (categoryA !== categoryB) {
      return categoriesOrder[categoryA] - categoriesOrder[categoryB];
    } else {
      return a.name.localeCompare(b.name);
    }
  }

  const coffee = newSR.filter(pr => pr.kategory == 'Кофемашина')
  const drincs = newSR.filter(pr => pr.kategory == 'Напитки без кофе')
  const nocoffee = newSR.filter(pr => pr.kategory != 'Кофемашина' && pr.kategory != 'Напитки без кофе')
  const cups = smenasclad.filter(pr => pr.kategory == 'Стаканы/Крышки')
  const nocups = smenasclad.filter(pr => pr.kategory != 'Стаканы/Крышки')
  // require('../../../pages/auth/unnamed-2.jpg')
  // console.log("newSR", newSR, newSRID, coffee, drincs, nocoffee, cups, nocups)
  coffee.sort(customSort2)
  drincs.sort(customSort2)
  nocoffee.sort(customSort2)
  let totlaMoney = 0
  let totalsale = 0
  let totalabom = 0
  let totallost = 0
  let totalsalewithoutcup = 0
  let totallostwithoutcup = 0
  for (let i = 0; i < newSR.length; i++) {
    totlaMoney = totlaMoney + newSR[i].sold * newSR[i].price
    totalsale = totalsale + (newSR[i].sale10 * 0.1 + newSR[i].sale20 * 0.2 + newSR[i].sale30 * 0.3) * newSR[i].price
    totallost = totallost + newSR[i].lostqty * newSR[i].price
    totalabom = totalabom + newSR[i].saleqty * newSR[i].price
    totallostwithoutcup = totallostwithoutcup + newSR[i].lostwithoutcup * newSR[i].price
    totalsalewithoutcup = totalsalewithoutcup + newSR[i].salewithoutcup * newSR[i].price

    if (newSR[i].linked) {
      for (let i2 = 0; i2 < newSR[i].linked.length; i2++) {
        totlaMoney = totlaMoney + newSR[i].linked[i2].sold * newSR[i].linked[i2].price
      }
    }
  }
  const finish = async () => {
    try {
      seloading(true)





      const same = smenasclad.filter(pr => pr.comfirm == false)
      if (same.length == 0) {
        const refresh = await checkAuth()
        if (refresh) {
          const newProd = await $api.post(`${BACKURL}/api/salereports/finish/${newSRID.id}`,
            { newSRID: newSRID, newSR: newSR, smenasclad: smenasclad, admin: admin.id, total: (totlaMoney - totalsale) }

          )
          setSaleReports(newProd.data)
          // setComfirmReportModal(false)



          setSaleReportSteps(false)
         await  CloseSmenaReturn()
        }
        Notify.addNotification({
          title: "Готово!",
          message: "Завершено! Отчет успешно отправлен!",
          type: "success",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      } else {
        Notify.addNotification({
          title: "Не все доделано!",
          message: "Подтвердите все остатки на конец дня!",
          type: "warning",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
      }

      seloading(false)



    } catch (e) {
      Notify.addNotification({
        title: "Ошибка!",
        message: "Произошла непредвиденная ошибка!",
        type: "danger",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }

  }
  const submit = async () => {
    confirmAlert({
      title: 'Отправить отчет?',
      message: 'Вы уверены, что хотите отправить отчет? Изменить его уже будет нельзя! Вы внимательно проверили данные?',
      buttons: [
        {
          label: 'Да!',
          onClick: async () => await finish()
        },
        {
          label: 'Отмена!',
          //   onClick: () => alert('Click No')
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };
  // console.log(totlaMoney, totalsale, totallost, totalsalewithoutcup, totallostwithoutcup)
  return (
    <>
      {/* <ReactNotifications /> */}
      {saleReportSteps && (
        <div
          //  onClick={closeModal}
          id="c2ontainer12464qqqq" className={style.modal_container}
        // style={{backgroundImage: "url('../../../pages/auth/unnamed-2.jpg')"}}
        >
          <div className={style.borders2} style={{ height: 'inherit' }}>
            <h2 id={'select'} ref={ref} style={{ color: "white" }}> {shop.name}</h2>
            <h3 style={{ color: "white" }}>{(totlaMoney - totalsale).toFixed()} РУБ</h3>
            <div className={style.nextitem}>
              <div className={style.uuu} style={{ flexDirection: 'row' }}>
                <BiSolidSave style={{ color: "white" }} onClick={() => savesr()}></BiSolidSave>

              </div>
              {page != 'ACTION' && (
                < Button
                  text="Выход"
                  background="var(--primary-color)"
                  color="white"
                  width="100px"
                  onClick={() => {
                    setSaleReportSteps(false)

                  }}
                />)}

              < Button
                text="Отправить Отчёт"
                background="darkslateblue"
                color="white"
                width="100px"
                onClick={() => submit()}
              />
              {page == 'ACTION' && (
                < Button
                  text="Назад"
                  background="darkgreen"
                  color="white"
                  width="100px"
                  onClick={() => setPage('REPORT')}
                />)}
            </div>
            {page == 'REPORT' && (
              <div style={{ display: 'flex', width: "100%", flexDirection: 'column', textAlign: "center" }} >
                <Tabs >
                  <TabList>
                    <Tab style={{ background: 'none', border: 'none', color: "white" }} key={"Продажи"}><h5>Продажи</h5></Tab>

                    <Tab style={{ background: 'none', border: 'none', color: "white" }} key={'Счетчики'}><h5>Счетчики</h5></Tab>
                    <Tab style={{ background: 'none', border: 'none', color: "white" }} key={'Остатки'}><h5>Остатки</h5></Tab>
                  </TabList>
                  {/* counters */}
                  <TabPanel>
                    <Tabs forceRenderTabPanel>
                      <TabList>
                        <Tab style={{ background: 'none', border: 'none', color: "white", opacity: "0.65" }} key={'Кофе'}><h6>Кофе</h6></Tab>
                        <Tab style={{ background: 'none', border: 'none', color: "white", opacity: "0.65" }} key={"Напитки"}><h6>Напитки</h6> </Tab>
                        <Tab style={{ background: 'none', border: 'none', color: "white", opacity: "0.65" }} key={'Прочее'}><h6>Прочее</h6> </Tab>
                      </TabList>

                      <TabPanel>
                        <div>
                          {coffee.map((cof, index) => <>
                            <div className={style.itemdd} >
                              {/* <div style={{width:"100%"}}> */}
                              <div style={{ width: "50%" }}>{cof.name}</div>
                              <div style={{ width: "30%", display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                <FaMinus onClick={(e) => {

                                  setNewSR(newSR.map(pr => {
                                    if (pr.id == cof.id && pr.sold - 1 >= 0) {
                                      let addsolded = 0
                                      if (pr.linked.length > 0) {
                                        for (let i = 0; i < pr.linked.length; i++) {
                                          addsolded = addsolded + (Number(pr.linked[i].sold))

                                        }
                                      }
                                      return {
                                        ...pr,
                                        newqty: Number(pr.lostwithoutcup) + Number(pr.salewithoutcup) + Number(pr.oldqty) + Number(addsolded) + Number(pr.sold) + Number(pr.lostqty) + Number(pr.saleqty) - 1,
                                        sold: pr.sold - 1
                                      }
                                    } else {
                                      return pr
                                    }
                                  }))
                                }} />
                                <div
                                  // value={cof.newqty-cof.oldqty}
                                  style={{ width: "30%", textAlign: "center" }}

                                  value={cof.sold} placeholder="Продано">
                                  {cof.sold}
                                </div>
                                <FaPlus onClick={(e) => {
                                  // console.log(e)
                                  setNewSR(newSR.map(pr => {
                                    if (pr.id == cof.id && pr.sold + 1 >= 0) {
                                      let addsolded = 0
                                      if (pr.linked.length > 0) {
                                        for (let i = 0; i < pr.linked.length; i++) {
                                          addsolded = addsolded + (Number(pr.linked[i].sold))

                                        }
                                      }
                                      return {
                                        ...pr,
                                        newqty: Number(pr.lostwithoutcup) + Number(pr.salewithoutcup) + Number(pr.oldqty) + Number(addsolded) + Number(pr.sold) + Number(pr.lostqty) + Number(pr.saleqty) + 1,
                                        sold: pr.sold + 1
                                      }
                                    } else {
                                      return pr
                                    }
                                  }))
                                }} />
                              </div>
                              <div style={{ width: "20%" }}>{cof.sold * cof.price - cof.sale10 * 0.1 * cof.price - cof.sale20 * 0.2 * cof.price - cof.sale30 * 0.3 * cof.price}РУБ</div>

                            </div>
                            {/* {coffee.linked && (<> */}
                            {cof.linked.map(pr => {
                              const item = reciepts.find(prq => pr.id == prq.id)
                              // console.log(item.name, cof.linked, pr, item)
                              return (
                                <div className={style.itemdd} >
                                  {/* <div style={{width:"100%"}}> */}
                                  <div style={{ width: "50%" }}>{item.name}</div>
                                  <div style={{ width: "30%", display: "flex", justifyContent: "center", alignItems: 'center' }}>

                                    <FaMinus onClick={(e) => {
                                      // console.log(e)
                                      const newLinked = cof.linked.map(prAdd => {
                                        if (prAdd.id == pr.id) {
                                          return (
                                            {
                                              ...prAdd,
                                              price: item.price,
                                              sold: prAdd.sold - 1
                                            }
                                          )
                                        } else {
                                          return prAdd
                                        }
                                      })
                                      setNewSR(newSR.map(sr => {
                                        if (sr.id == cof.id) {
                                          let addsolded = 0
                                          if (sr.linked.length > 0) {
                                            for (let i = 0; i < sr.linked.length; i++) {
                                              addsolded = addsolded + (Number(sr.linked[i].sold))

                                            }
                                          }
                                          return {
                                            ...sr,
                                            newqty: Number(sr.lostwithoutcup) + Number(sr.salewithoutcup) + Number(sr.oldqty) + Number(addsolded) + Number(sr.sold) + Number(sr.lostqty) + Number(sr.saleqty) - 1,
                                            linked: newLinked,
                                          }
                                        } else {
                                          return sr
                                        }
                                      }))
                                    }} />
                                    <div
                                      style={{ width: "30%", textAlign: "center" }}
                                      onChange={(e) => {
                                        // console.log(e)
                                        // setNewSR(newSR.map(pr => {
                                        //   if (pr.id == cof.id) {
                                        //     return { ...pr, sold: e.target.value }
                                        //   } else {
                                        //     return pr
                                        //   }
                                        // }))
                                      }}

                                      value={pr.sold} placeholder="Продано">
                                      {pr.sold}
                                    </div>
                                    <FaPlus onClick={(e) => {
                                      // console.log(e)
                                      const newLinked = cof.linked.map(prAdd => {
                                        if (prAdd.id == pr.id) {
                                          return (
                                            {
                                              ...prAdd,
                                              price: item.price,

                                              sold: prAdd.sold + 1
                                            }
                                          )
                                        } else {
                                          return prAdd
                                        }
                                      })
                                      setNewSR(newSR.map(sr => {
                                        if (sr.id == cof.id) {
                                          let addsolded = 0
                                          if (sr.linked.length > 0) {
                                            for (let i = 0; i < sr.linked.length; i++) {
                                              addsolded = addsolded + (Number(sr.linked[i].sold))

                                            }
                                          }
                                          return {
                                            ...sr,
                                            newqty: Number(sr.lostwithoutcup) + Number(sr.salewithoutcup) + Number(sr.oldqty) + Number(addsolded) + Number(sr.sold) + Number(sr.lostqty) + Number(sr.saleqty) + 1,
                                            linked: newLinked,
                                          }
                                        } else {
                                          return sr
                                        }
                                      }))
                                    }} />
                                  </div>
                                  <div style={{ width: "20%" }}>{pr.sold * item.price}РУБ</div>

                                </div>
                              )
                            })}
                            {/* </>)} */}
                          </>
                          )}
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div>
                          {drincs.map((cof, index) => <div className={style.itemdd} >
                            {/* <div style={{width:"100%"}}> */}
                            <div style={{ width: "50%" }}>{cof.name}</div>
                            <div style={{ width: "30%", display: "flex", justifyContent: "center", alignItems: 'center' }}>
                              <FaMinus onClick={(e) => {
                                // console.log(e)
                                setNewSR(newSR.map(pr => {
                                  if (pr.id == cof.id && pr.sold - 1 >= 0) {
                                    return { ...pr, sold: pr.sold - 1 }
                                  } else {
                                    return pr
                                  }
                                }))
                              }} />
                              <div
                                style={{ width: "30%", textAlign: "center" }}
                                // onChange={(e) => {
                                // console.log(e)
                                //   setNewSR(newSR.map(pr => {
                                //     if (pr.id == cof.id) {
                                //       return { ...pr, sold: e.target.value }
                                //     } else {
                                //       return pr
                                //     }
                                //   }))
                                // }}
                                value={cof.sold} placeholder="Продано">
                                {cof.sold}
                              </div>
                              <FaPlus onClick={(e) => {
                                // console.log(e)
                                setNewSR(newSR.map(pr => {
                                  if (pr.id == cof.id && pr.sold + 1 >= 0) {
                                    return { ...pr, sold: pr.sold + 1 }
                                  } else {
                                    return pr
                                  }
                                }))
                              }} />
                            </div>
                            <div style={{ width: "20%" }}>{cof.sold * cof.price}РУБ</div>

                          </div>)}
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div>
                          {nocoffee.map((cof, index) => <div className={style.itemdd} >
                            {/* <div style={{width:"30%"}}> */}
                            <div style={{ width: "50%" }}>{cof.name}</div>
                            <div style={{ width: "30%", display: "flex", justifyContent: "center", alignItems: 'center' }}>
                              <FaMinus onClick={(e) => {
                                // console.log(e)
                                setNewSR(newSR.map(pr => {
                                  if (pr.id == cof.id && pr.sold - 1 >= 0) {
                                    return { ...pr, sold: pr.sold - 1 }
                                  } else {
                                    return pr
                                  }
                                }))
                              }} />
                              <div
                                style={{ width: "30%", textAlign: "center" }}
                                // onChange={(e) => {
                                // console.log(e)
                                //   setNewSR(newSR.map(pr => {
                                //     if (pr.id == cof.id) {
                                //       return { ...pr, sold: e.target.value }
                                //     } else {
                                //       return pr
                                //     }
                                //   }))
                                // }}
                                value={cof.sold} placeholder="Продано">
                                {cof.sold}

                              </div>
                              <FaPlus onClick={(e) => {
                                // console.log(e)
                                setNewSR(newSR.map(pr => {
                                  if (pr.id == cof.id && pr.sold + 1 >= 0) {
                                    return { ...pr, sold: pr.sold + 1 }
                                  } else {
                                    return pr
                                  }
                                }))
                              }} />
                            </div>
                            <div style={{ width: "20%" }}>{cof.sold * cof.price}РУБ</div>

                          </div>)}
                        </div>
                      </TabPanel>
                    </Tabs>
                  </TabPanel>
                  <TabPanel>
                    <div>
                      {coffee.map((cof, index) =>
                        <div className={style.itemdd} >
                          {/* <div style={{width:"100%"}}> */}
                          <div style={{ width: "40%" }}>{cof.name}</div>
                          {/* <div  style={{width:"30%"}}> */}
                          <div style={{ width: "20%", opacity: "0.65" }}>{cof.oldqty}</div>
                          <div style={{ width: "20%" }}>
                            <input
                              style={{ width: "100%", textAlign: "center" }}
                              onChange={(e) => {
                                // console.log(e)
                                setNewSR(newSR.map(pr => {
                                  if (pr.id == cof.id) {
                                    return { ...pr, newqty: e.target.value }
                                  } else {
                                    return pr
                                  }
                                }))
                              }}
                              value={cof.newqty} placeholder="На конец дня">

                            </input>
                          </div>
                          {/* </div> */}
                          <div style={{ width: "20%" }}>
                            <b>  <RiDiscountPercentFill style={{ fontSize: "30px", color: "#CD5A35" }} onClick={() => {
                              setactionId(cof)
                              setPage('ACTION')
                            }}></RiDiscountPercentFill></b></div>
                        </div>

                      )}
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ width: "35%", color: "white" }}><h6>Наименование</h6></div>
                      {/* <div  style={{width:"30%"}}> */}
                      <div style={{ width: "10%", color: "white" }}><h6>+</h6></div>
                      <div style={{ width: "10%", color: "white" }}><h6>-</h6></div>
                      <div style={{ width: "15%", color: "white" }}><h6>Утро</h6></div>
                      <div style={{ width: "15%", color: "white" }}><h6>Вечер</h6></div>
                      <div style={{ width: "15%", color: "white" }}><h6><FaCheck /></h6></div>
                    </div>
                    <div>
                      {nocups.map((cof, index) => <div className={style.itemdd} >

                        {/* </div> */}
                        <div style={{ width: "35%" }}>{cof.name}</div>
                        {/* <div  style={{width:"30%"}}> */}
                        <div style={{ width: "10%" }}>{cof.prihod}</div>
                        <div style={{ width: "10%" }}>{cof.rashod}</div>
                        <div style={{ width: "15%" }}>{cof.scldqty}</div>
                        <div style={{ width: "15%" }}>
                          <input
                            style={{ width: "100%", textAlign: "center" }}
                            onChange={(e) => {
                              // console.log(e)
                              const regex = /^[0-9.]*$/;
                              if (regex.test(e.target.value)) {
                                setSmenaSclad(smenasclad.map(pr => {
                                  // if (regex.test(e.target.value)) {
                                  if (pr.id == cof.id && pr.name == cof.name) {
                                    return { ...pr, andqty: e.target.value }
                                  } else {
                                    return pr
                                  }
                                }))
                              }
                            }}
                            value={cof.andqty} placeholder="На конец дня">

                          </input>
                        </div>
                        <div style={{ width: "15%" }}
                          onClick={(e) => {
                            // console.log(e)
                            setSmenaSclad(smenasclad.map(pr => {
                              if (pr.prod_id == cof.prod_id && pr.name == cof.name) {
                                return { ...pr, comfirm: pr.comfirm ? !pr.comfirm : true }
                              } else {
                                return pr
                              }
                            }))
                          }}
                          value={cof.comfirm}
                        >{cof.comfirm ? <FcOk /> : <MdOutlineRadioButtonUnchecked />}</div>
                      </div>
                      )}
                    </div>
                    <div>
                      {cups.map((cof, index) => <div className={style.itemdd} >

                        {/* </div> */}
                        <div style={{ width: "35%" }}>{cof.name}</div>
                        {/* <div  style={{width:"30%"}}> */}
                        <div style={{ width: "10%" }}>{cof.prihod}</div>
                        <div style={{ width: "10%" }}>{cof.rashod}</div>
                        <div style={{ width: "15%" }}>{cof.scldqty}</div>
                        <div style={{ width: "15%" }}>
                          <input
                            style={{ width: "100%", textAlign: "center" }}
                            onChange={(e) => {
                              // console.log(e)
                              const regex = /^[0-9.]*$/;

                              if (regex.test(e.target.value)) {
                                setSmenaSclad(smenasclad.map(pr => {
                                  if (pr.id == cof.id && pr.name == cof.name) {
                                    return { ...pr, andqty: e.target.value }
                                  } else {
                                    return pr
                                  }
                                }))
                              }
                            }}
                            value={cof.andqty} placeholder="На конец дня">

                          </input>
                        </div>
                        <div style={{ width: "15%" }}
                          onClick={(e) => {
                            // console.log(e)
                            setSmenaSclad(smenasclad.map(pr => {
                              if (pr.prod_id == cof.prod_id && pr.name == cof.name) {
                                return { ...pr, comfirm: pr.comfirm ? !pr.comfirm : true }
                              } else {
                                return pr
                              }
                            }))
                          }}
                          value={cof.comfirm}
                        >{cof.comfirm ? <FcOk /> : <MdOutlineRadioButtonUnchecked />}</div>
                      </div>)}
                    </div>
                  </TabPanel>




                </Tabs >


              </div>
            )}
            {page == 'ACTION' && (
              <div style={{ display: 'flex', width: "100%", flexDirection: 'column', textAlign: "center" }} >
                <h3 style={{ color: "white" }}>{actionId.name}</h3>
                <h4 style={{ color: "white" }}>{actionId.sold * actionId.price - actionId.sale10 * 0.1 * actionId.price - actionId.sale20 * 0.2 * actionId.price - actionId.sale30 * 0.3 * actionId.price}РУБ</h4>
                <hr style={{ border: "none", margin: "15px 0", backgroundColor: "brown", height: "px" }} />
                <h5 style={{ color: "white" }}>Абонементы</h5>

                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "row" }}>
                  <div style={{ width: "50%", display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "row" }}>

                    <FaMinus style={{ color: 'white', fontSize: '20px' }} onClick={(e) => {

                      setNewSR(newSR.map(pr => {

                        if (pr.id == actionId.id && actionId.saleqty - 1 >= 0) {
                          setactionId({ ...actionId, saleqty: actionId.saleqty - 1 })
                          let addsolded = 0
                          if (pr.linked.length > 0) {
                            for (let i = 0; i < pr.linked.length; i++) {
                              addsolded = addsolded + (Number(pr.linked[i].sold))

                            }
                          }
                          return {
                            ...pr,
                            newqty: Number(pr.lostwithoutcup) + Number(pr.salewithoutcup) + Number(pr.oldqty) + Number(addsolded) + Number(pr.sold) + Number(pr.lostqty) + Number(pr.saleqty) - 1,
                            saleqty: actionId.saleqty - 1
                          }
                        } else {
                          return pr
                        }
                      }))
                      console.log(newSR)
                    }} />
                    <div
                      // value={cof.newqty-cof.oldqty}
                      style={ieconStyle}
                    >
                      {actionId.saleqty}
                    </div>
                    <FaPlus style={{ color: 'white', fontSize: '20px' }} onClick={(e) => {
                      console.log(actionId)
                      setNewSR(newSR.map(pr => {
                        if (pr.id == actionId.id && actionId.saleqty + 1 >= 0) {
                          setactionId({ ...actionId, saleqty: actionId.saleqty + 1 })
                          let addsolded = 0
                          if (pr.linked.length > 0) {
                            for (let i = 0; i < pr.linked.length; i++) {
                              addsolded = addsolded + (Number(pr.linked[i].sold))

                            }
                          }
                          return {
                            ...pr,
                            newqty: Number(pr.lostwithoutcup) + Number(pr.salewithoutcup) + Number(pr.oldqty) + Number(addsolded) + Number(pr.sold) + Number(pr.lostqty) + Number(pr.saleqty) + 1,
                            saleqty: actionId.saleqty + 1
                          }
                        } else {
                          return pr
                        }
                      }))
                    }} />

                  </div>

                  <div style={{ width: "50%", display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    <textarea placeholder="Абонементы"
                      value={actionId.salecomment}
                      onChange={(e) => {
                        setNewSR(newSR.map(pr => {
                          if (pr.id == actionId.id) {
                            setactionId({ ...actionId, salecomment: e.target.value })

                            return { ...pr, salecomment: e.target.value }
                          } else {
                            return pr
                          }
                        }))
                      }}>

                    </textarea>
                  </div>
                </div>
                <hr style={{ border: "none", margin: "15px 0", backgroundColor: "darkred", height: "3px" }} />
                <div>
                  <h5 style={{ color: "white" }}>Карты Скидок</h5>

                  <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "row" }}>
                    <div style={{ width: "27%", margin: '3%', display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "column" }}>
                      <h5>10%</h5>
                      <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "row" }}>
                        <FaMinus style={{ color: 'white', fontSize: '20px' }} onClick={(e) => {
                          console.log(actionId)
                          setNewSR(newSR.map(pr => {
                            if (pr.id == actionId.id && actionId.sale10 - 1 >= 0) {
                              setactionId({ ...actionId, sale10: actionId.sale10 - 1 })

                              return { ...pr, sale10: actionId.sale10 - 1 }
                            } else {
                              return pr
                            }
                          }))
                          console.log(actionId)
                        }} />
                        <div
                          // value={cof.newqty-cof.oldqty}
                          style={ieconStyle}
                        >
                          {actionId.sale10 ? actionId.sale10 : 0}
                        </div>
                        <FaPlus style={{ color: 'white', fontSize: '20px' }} onClick={(e) => {
                          console.log(actionId)

                          setNewSR(newSR.map(pr => {
                            if (pr.id == actionId.id && actionId.sale10 + 1 >= 0) {
                              setactionId({ ...actionId, sale10: actionId.sale10 + 1 })

                              return { ...pr, sale10: actionId.sale10 + 1 }
                            } else {
                              return pr
                            }
                          }))
                        }} />
                      </div>
                    </div>
                    <div style={{ width: "27%", margin: '3%', display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "column" }}>
                      <h5>20%</h5>

                      <div style={{ width: "100%%", display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "row" }}>
                        <FaMinus style={{ color: 'white', fontSize: '20px' }} onClick={(e) => {
                          setNewSR(newSR.map(pr => {
                            if (pr.id == actionId.id && actionId.sale20 - 1 >= 0) {
                              setactionId({ ...actionId, sale20: actionId.sale20 - 1 })

                              return { ...pr, sale20: actionId.sale20 - 1 }
                            } else {
                              return pr
                            }
                          }))
                        }} />
                        <div
                          // value={cof.newqty-cof.oldqty}
                          style={ieconStyle}
                        >
                          {actionId.sale20 ? actionId.sale20 : 0}
                        </div>
                        <FaPlus style={{ color: 'white', fontSize: '20px' }} onClick={(e) => {
                          setNewSR(newSR.map(pr => {
                            if (pr.id == actionId.id && actionId.sale20 + 1 >= 0) {
                              setactionId({ ...actionId, sale20: actionId.sale20 + 1 })

                              return { ...pr, sale20: actionId.sale20 + 1 }
                            } else {
                              return pr
                            }
                          }))
                        }} />
                      </div>
                    </div>
                    <div style={{ width: "27%", margin: '3%', display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "column" }}>
                      <h5 >30%</h5>

                      <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "row" }}>
                        <FaMinus style={{ color: 'white', fontSize: '20px' }} onClick={(e) => {
                          setNewSR(newSR.map(pr => {
                            if (pr.id == actionId.id && actionId.sale30 - 1 >= 0) {
                              setactionId({ ...actionId, sale30: actionId.sale30 - 1 })

                              return { ...pr, sale30: actionId.sale30 - 1 }
                            } else {
                              return pr
                            }
                          }))
                        }} />
                        <div
                          // value={cof.newqty-cof.oldqty}
                          style={ieconStyle}
                        >
                          {actionId.sale30 ? actionId.sale30 : 0}
                        </div>
                        <FaPlus style={{ color: 'white', fontSize: '20px' }} onClick={(e) => {
                          setNewSR(newSR.map(pr => {
                            if (pr.id == actionId.id && actionId.sale30 + 1 >= 0) {
                              setactionId({ ...actionId, sale30: actionId.sale30 + 1 })

                              return { ...pr, sale30: actionId.sale30 + 1 }
                            } else {
                              return pr
                            }
                          }))
                        }} />
                      </div>
                    </div>
                  </div>
                </div>
                <hr style={{ border: "none", margin: "15px 0", backgroundColor: "darkred", height: "3px" }} />
                <h5 style={{ color: "white" }}>Списано</h5>
                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "row" }}>

                  <div style={{ width: "50%", display: "flex", justifyContent: "center", alignItems: 'center' }}>

                    <FaMinus style={{ color: 'white', fontSize: '20px' }} onClick={(e) => {
                      setNewSR(newSR.map(pr => {
                        if (pr.id == actionId.id && actionId.lostqty - 1 >= 0) {
                          setactionId({ ...actionId, lostqty: actionId.lostqty - 1 })
                          let addsolded = 0
                          if (pr.linked.length > 0) {
                            for (let i = 0; i < pr.linked.length; i++) {
                              addsolded = addsolded + (Number(pr.linked[i].sold))

                            }
                          }
                          return {
                            ...pr,
                            newqty: Number(pr.lostwithoutcup) + Number(pr.salewithoutcup) + Number(pr.oldqty) + Number(addsolded) + Number(pr.sold) + Number(pr.lostqty) + Number(pr.saleqty) - 1,
                            lostqty: actionId.lostqty - 1
                          }
                        } else {
                          return pr
                        }
                      }))
                    }} />
                    <div
                      // value={cof.newqty-cof.oldqty}
                      style={ieconStyle}
                    >
                      {actionId.lostqty ? actionId.lostqty : 0}
                    </div>
                    <FaPlus style={{ color: 'white', fontSize: '20px' }} onClick={(e) => {
                      setNewSR(newSR.map(pr => {
                        if (pr.id == actionId.id && actionId.lostqty + 1 >= 0) {
                          setactionId({ ...actionId, lostqty: actionId.lostqty + 1 })
                          let addsolded = 0
                          if (pr.linked.length > 0) {
                            for (let i = 0; i < pr.linked.length; i++) {
                              addsolded = addsolded + (Number(pr.linked[i].sold))

                            }
                          }
                          return {
                            ...pr,
                            newqty: Number(pr.lostwithoutcup) + Number(pr.salewithoutcup) + Number(pr.oldqty) + Number(addsolded) + Number(pr.sold) + Number(pr.lostqty) + Number(pr.saleqty) + 1,
                            lostqty: actionId.lostqty + 1
                          }
                        } else {
                          return pr
                        }
                      }))
                    }} />

                  </div>

                  <div style={{ width: "50%", display: "flex", justifyContent: "center", alignItems: 'center' }}>
                    <textarea placeholder="Причина"
                      value={actionId.lostcomment}
                      onChange={(e) => {
                        setNewSR(newSR.map(pr => {
                          if (pr.id == actionId.id) {
                            setactionId({ ...actionId, lostcomment: e.target.value })

                            return { ...pr, lostcomment: e.target.value }
                          } else {
                            return pr
                          }
                        }))
                      }}>

                    </textarea>
                  </div>
                </div>
                <hr style={{ border: "none", margin: "15px 0", backgroundColor: "darkred", height: "3px" }} />
                <div>
                  <h5 style={{ color: "white" }}>Списано на сотрудника без стакана</h5>
                  <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: 'center' }}>

                    <FaMinus style={{ color: 'white', fontSize: '20px' }} onClick={(e) => {
                      setNewSR(newSR.map(pr => {
                        if (pr.id == actionId.id && actionId.lostwithoutcup - 1 >= 0) {
                          setactionId({ ...actionId, lostwithoutcup: actionId.lostwithoutcup - 1 })
                          let addsolded = 0
                          if (pr.linked.length > 0) {
                            for (let i = 0; i < pr.linked.length; i++) {
                              addsolded = addsolded + (Number(pr.linked[i].sold))

                            }
                          }
                          return {
                            ...pr,
                            newqty: Number(pr.lostwithoutcup) + Number(pr.salewithoutcup) + Number(pr.oldqty) + Number(addsolded) + Number(pr.sold) + Number(pr.lostqty) + Number(pr.saleqty) - 1,
                            lostwithoutcup: actionId.lostwithoutcup - 1
                          }
                        } else {
                          return pr
                        }
                      }))
                    }} />
                    <div
                      // value={cof.newqty-cof.oldqty}
                      style={ieconStyle}
                    >
                      {actionId.lostwithoutcup ? actionId.lostwithoutcup : 0}
                    </div>
                    <FaPlus style={{ color: 'white', fontSize: '20px' }} onClick={(e) => {
                      setNewSR(newSR.map(pr => {
                        if (pr.id == actionId.id && actionId.lostwithoutcup + 1 >= 0) {
                          setactionId({ ...actionId, lostwithoutcup: actionId.lostwithoutcup + 1 })
                          let addsolded = 0
                          if (pr.linked.length > 0) {
                            for (let i = 0; i < pr.linked.length; i++) {
                              addsolded = addsolded + (Number(pr.linked[i].sold))

                            }
                          }
                          return {
                            ...pr,
                            newqty: Number(pr.lostwithoutcup) + Number(pr.salewithoutcup) + Number(pr.oldqty) + Number(addsolded) + Number(pr.sold) + Number(pr.lostqty) + Number(pr.saleqty) + 1,
                            lostwithoutcup: actionId.lostwithoutcup + 1
                          }
                        } else {
                          return pr
                        }
                      }))
                    }} />

                  </div>


                </div>
                <hr style={{ border: "none", margin: "15px 0", backgroundColor: "darkred", height: "3px" }} />
                <div>
                  <h5 style={{ color: "white" }}>Продано без стакана</h5>
                  <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: 'center' }}>

                    <FaMinus style={{ color: 'white', fontSize: '20px' }} onClick={(e) => {
                      setNewSR(newSR.map(pr => {
                        if (pr.id == actionId.id) {
                          setactionId({ ...actionId, salewithoutcup: actionId.salewithoutcup - 1 })
                          let addsolded = 0
                          if (pr.linked.length > 0) {
                            for (let i = 0; i < pr.linked.length; i++) {
                              addsolded = addsolded + (Number(pr.linked[i].sold))

                            }
                          }
                          return {
                            ...pr,
                            newqty: Number(pr.lostwithoutcup) + Number(pr.salewithoutcup) + Number(pr.oldqty) + Number(addsolded) + Number(pr.sold) + Number(pr.lostqty) + Number(pr.saleqty) - 1,
                            salewithoutcup: actionId.salewithoutcup - 1
                          }
                        } else {
                          return pr
                        }
                      }))
                    }} />
                    <div
                      // value={cof.newqty-cof.oldqty}
                      style={ieconStyle}
                    >
                      {actionId.salewithoutcup ? actionId.salewithoutcup : 0}
                    </div>
                    <FaPlus style={{ color: 'white', fontSize: '20px' }} onClick={(e) => {
                      setNewSR(newSR.map(pr => {
                        if (pr.id == actionId.id) {
                          setactionId({ ...actionId, salewithoutcup: actionId.salewithoutcup + 1 })
                          let addsolded = 0
                          if (pr.linked.length > 0) {
                            for (let i = 0; i < pr.linked.length; i++) {
                              addsolded = addsolded + (Number(pr.linked[i].sold))

                            }
                          }
                          return {
                            ...pr,
                            newqty: Number(pr.lostwithoutcup) + Number(pr.salewithoutcup) + Number(pr.oldqty) + Number(addsolded) + Number(pr.sold) + Number(pr.lostqty) + Number(pr.saleqty) + 1,
                            salewithoutcup: actionId.salewithoutcup + 1
                          }
                        } else {
                          return pr
                        }
                      }))
                    }} />

                  </div>


                </div>
              </div>
            )}
            {/* {page == 'FINISH' && (
              <div style={{ display: 'flex', width: "100%", flexDirection: 'column', textAlign: "center" }} >
              
              </div>
            )} */}
          </div>

        </div >
      )}
      {
        loading && (
          <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
        )
      }

    </>
  )
}
export default SaleReportSteps;